<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="no-radius fixed-height">
        <accept-decline :campaign="campaign"/>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BCard } from "bootstrap-vue";
import acceptDecline from "./acceptDecline.vue";

export default {
  name: 'RightSideInvitation',
  components: {
    BCol,
    BRow,
    BCard,
    acceptDecline
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{attrs:{"color":"#7367F0","title":null,"subtitle":null,"next-button-text":_vm.$t('next'),"finish-button-text":_vm.$t('registerInvitation'),"back-button-text":_vm.$t('return')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('login.accountDetails'),"before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('register.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('register.name'),"vid":_vm.$t('register.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('register.name')},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('register.nameField')))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('register.lastname'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('register.lastname'),"vid":_vm.$t('register.lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","name":"lastname","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('register.lastname')},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('register.lastnameField')))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('register.email')},model:{value:(_vm.email_value),callback:function ($$v) {_vm.email_value=$$v},expression:"email_value"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('register.emailField')))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false:null,"placeholder":"Password"},model:{value:(_vm.password_value),callback:function ($$v) {_vm.password_value=$$v},expression:"password_value"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('register.passwordInvalid')))]):_vm._e()]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('login.personalInformation'),"before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('validation-provider',{attrs:{"name":"Celular"}},[_c('input-tel-brandme',{attrs:{"number_dad":_vm.telephone},on:{"number":_vm.updateTelephone}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cuéntanos sobre tu contenido","label-for":"description_content"}},[_c('b-form-textarea',{attrs:{"id":"description_content","placeholder":"Escribir mensaje...","rows":"3","required":true,"max-rows":"4"},model:{value:(_vm.description_content),callback:function ($$v) {_vm.description_content=$$v},expression:"description_content"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
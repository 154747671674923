<template>
  <div>
    <h4 class="title-terms">{{$t('invitation.terms')}}</h4>
    <p>{{$t('invitation.textTerms')}}</p>
    <b-form-checkbox
      v-if="!campaign_influencer.accept_tyc"
      v-model="accept_terms"
      :disabled="disable_check"
    >
      <p>{{$t('invitation.acceptTerms')}} <b-link @click="openModal">{{$t('invitation.linkTerms')}}</b-link></p>
    </b-form-checkbox>
    <div v-else>
      <p>{{$t('invitation.alreadyAccept')}} <b-link @click="openModal">{{$t('invitation.linkTerms')}}</b-link></p>
    </div>

    <modal-brandme size="lg" :show_modal="show_modal" :key="change_modal">
      <template #title>
        <span>{{$t('invitation.titleTerms')}}</span>
      </template>
      <template #body>
        <text-terms-and-condition/>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import { BFormCheckbox, BLink } from "bootstrap-vue";
import ModalBrandme from "@/views/components/modal/ModalBrandme.vue";
import TextTermsAndCondition from "../../authentication/TextTermsAndCondition.vue";
import invitation_service from "@/services/invitation";

export default {
  name: 'AccpetTerms',
  components: {
    BFormCheckbox,
    BLink,
    ModalBrandme,
    TextTermsAndCondition
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      disable_check: false,
      accept_terms: false,
      show_modal: false,
      change_modal: false,
      influencer: this.campaign.campaign_influencer.influencer,
      campaign_influencer: this.campaign.campaign_influencer
    }
  },
  created() {
  },
  methods: {
    openModal(evt) {
      evt.preventDefault()
      this.show_modal = true;
      this.change_modal = !this.change_modal
    }
  },
  watch: {
    accept_terms(val) {
      if (val) {
        this.disable_check = true
        invitation_service.accpetTerms(this.$route.params.slug, this.$route.params.network, this.$route.params.username)
          .then(() => {
            this.campaign_influencer.accept_tyc = true;
            this.disable_check = false
          })
      }
    }
  }
};
</script>

<style>
  .title-terms {
    color: #545454;
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Gilroy-ExtraBold';
  }
</style>

<template>
  <div v-if="content">
    <b-tabs v-model="tab_model" nav-class="fixed-tab" content-class="pt-3" class="tabs-sidebar-invitation">
      <b-tab :title="$t('youtube.content')">

        <b-col class="col-12 mb-1 pt-2">
          <b-form-datepicker
            :placeholder="$t('campaigns.date_publish')"
            v-model="date_publish"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
          ></b-form-datepicker>
        </b-col>

          <general-form
            class="col-12"
            :content="content"
            :uuids="{campaign_uuid, campaign_influencer}"
            :net="content.network"
            :info_add="{date_publish}"
            :content_type="content.content_type"
            @closeSidebar="closeSidebar"
          > 
          </general-form>
      </b-tab>
      <b-tab title="Overview">
        <tab-overview :campaign="campaign" :key="tab_model"/>
      </b-tab>
      <b-tab :title="$t('invitation.requirements')">
        <div v-if="(requirements.length > 0)">
          <div v-for="(requirement, index) in requirements" :key="index">
            <div class="m-1" v-html="requirement.form_value"></div>
          </div>
        </div>
        <div v-else class="m-1">
          <p>{{$t('invitation.noRequirements')}}</p>
        </div>

      </b-tab>
      <b-tab :title="$t('invitation.elements')">
        <div v-if="(elements.length > 0)">
          <div v-for="(element, index) in elements" :key="index">
            <div class="m-1" v-html="element.form_value"></div>
          </div>
        </div>
        <div v-else class="m-1">
          <p>{{$t('invitation.noElements')}}</p>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCol, BFormDatepicker } from "bootstrap-vue";
import utils from "@/libs/utils";
import GeneralForm from './forms/GeneralForm.vue';
import tabOverview from "./tabOverview.vue";

export default {
  name: 'detailsContent',
  components: {
    BTabs,
    BTab,
    GeneralForm,
    BCol,
    BFormDatepicker,
    tabOverview,
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    campaign: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      utils,
      requirements: [],
      elements: [],
      campaign_influencer: this.content.campaing_influencer,
      campaign_uuid: this.campaign.uuid,
      date_publish: this.content.published_date,
      tab_model: 0,
    }
  },
  created() {
    if (this.content) {
      this.requirements = this.campaign.custom_fields.filter(item => item.show_link && item.network && item.network === this.content.network && item.type_field === 'requirement')
      this.elements = this.campaign.custom_fields.filter(item => item.show_link && item.network && item.network === this.content.network && item.type_field === 'element')
    }
  },
  methods: {
    closeSidebar(data) {
      this.$emit('updateContent', data)
      this.$emit('closeSidebar');
    },
    formatDate(date_string, has_timezone = false) {
      if (!date_string) return '-'
      const date = new Date(date_string)
      if (!has_timezone) date.setDate(date.getDate() + 1)
      return utils.getDatev2(date)
    },
    getIconNet() {
      return utils.getDropdownNetworks().filter(i => i.value === this.content.network)[0].icon;
    },
  }
};
</script>

<style>
  .fixed-tab {
    position: absolute;
    z-index: 2;
    background: white;
  }
</style>

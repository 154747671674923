<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="no-radius border-right-lg fixed-height">
        <header-invitation :campaign="campaign"/>
        <banner-status class="m-2" :total_contents="total"/>
        <overview-campaign class="m-3" :campaign="campaign"/>
        <content-invitation class="m-2" :campaign="campaign" @plusContent="plusContent"/>
        <accpet-terms class="m-3" :campaign="campaign"/>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BCard } from "bootstrap-vue";
import headerInvitation from "./headerInvitation.vue";
import bannerStatus from "./bannerStatus.vue";
import overviewCampaign from "./overviewCampaign.vue";
import contentInvitation from "./contentInvitation.vue";
import accpetTerms from "./accpetTerms.vue";

export default {
  name: 'LeftSideInvitation',
  components: {
    BCol,
    BRow,
    BCard,
    headerInvitation,
    bannerStatus,
    overviewCampaign,
    contentInvitation,
    accpetTerms
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
    total_contents: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      total: this.total_contents
    }
  },
  methods: {
    plusContent() {
      this.total += 1;
    },
  }
};
</script>

<style>
</style>

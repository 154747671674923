<template>
    <b-card
      class="mb-0 card-header-invitation"
      body-class="p-0 w-100"
      header-class="img-cover-card"
    >
      <template #header>
        <div></div>
      </template>

      <div class="container-avatar">
        <b-avatar
          class="avatar-header ml-2"
          size="120"
          variant="light"
          :src="campaign.logo"
          badge
          :badge-variant="getVariant()"
          badge-offset="0.5em"
        >
        </b-avatar>
        <b-card-title class="ml-2 text-over-img">
          <b-card-text>
            {{campaign.name}}
          </b-card-text>
        </b-card-title>
        <b-card-title v-if="campaign.brand_reference && campaign.brand_reference.name" class="ml-2 text-brand text-muted">
          {{$t('invitation.byBrand')}}: {{campaign.brand_reference.name.substr(0, 120)}}
        </b-card-title>
      </div>
    </b-card>
</template>

<script>
import { BCard, BAvatar, BCardTitle, BCardText } from "bootstrap-vue";
import utils from "@/libs/utils"

export default {
  name: 'HeaderInvitation',
  components: {
    BCard,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      utils
    }
  },
  mounted() {
    const img_card = document.querySelector('.img-cover-card')
    img_card.style.setProperty('background-image',  `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 89%), url(${this.campaign.cover})`)
  },
  methods: {
    getVariant() {
      return this.campaign.is_archived ? 'warning' : 'success';
    },
  }
};
</script>

<style>
  .img-cover-card {
    background-size: cover;
    color: white;
    padding: 20px;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  .avatar-header .b-avatar-badge {
    font-size: 15px !important;
  }
  .text-over-img {
    color: #FFF;
    position: absolute;
    top: 1.5em;
    left: 10rem;
  }
  .text-brand {
    position: absolute;
    top: 5rem;
    left: 10rem;
  }
</style>
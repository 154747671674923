<template>
  <div class="d-flex h-100 flex-column">
    <div class="d-flex justify-content-center flex-wrap mt-1">
      <div class="col-12 col-sm-4 col-lg-12 col-xl-4">
        <b-media class="d-block col-12">
          <template #aside>
            <div class="container-avatar-table">
              <b-avatar
                size="120px"
                :src="getImageInfluencer(influencer)"
                variant="light-primary"
              />
              <div class="container-avatar-table-icon">
                <b-avatar size="25" variant="light">
                  <b-img :src="getIconWhite(influencer.network)" :class="`${getIconColor(influencer.network, false)}`" fluid></b-img>
                </b-avatar>
              </div>
            </div>
          </template>
        </b-media>
      </div>
      <div class="col-12 col-sm-5 col-lg-12 col-xl-5 d-flex flex-wrap text-no-break">
        <h3 class="col-12 text-center mt-1">
          {{influencer.username}}
        </h3>
        <b-badge :variant="statusVariant(campaign_influencer.status)" class="mt-1">
          {{ $t(getStatus(campaign_influencer.status)) }}
        </b-badge>
        <div class="col-12 text-center mt-1 row">
          <span class="text-muted col-12">
            {{$t('lists.followers')}}
          </span>
          <span class="col-12">
            {{getFormat(influencer.followers)}}
          </span>
        </div>
        <div class="col-12 text-center mt-1 row">
          <span class="text-muted col-12">
            {{ $t('budgetcard') }}
          </span>
          <span class="col-12">
            ${{separatebycomma(campaign.budget)}}
          </span>
        </div>
      </div>
    </div>
    <div class="m-2" v-html="campaign.custom_message"></div>
    <div class="text-center m-2" v-if="campaign.campaign_influencer.status_influencer !== 'approved'">
      <p>{{$t('campaigns.questionApproveProposal')}}</p>
      <b-button variant="success" @click="verifyTYC()">{{$t('campaigns.realty.accept')}}</b-button>
    </div>
    <div class="m-2" v-if="campaign.campaign_influencer.status_influencer === 'approved' && !influencer.has_payment_data">
      <paymentData :campaign="campaign" @savePaymentData="savePaymentData"/>
    </div>
    <div class="m-2 text-center" v-if="campaign.campaign_influencer.status_influencer === 'approved' && influencer.has_payment_data">
      <strong>{{$t('campaigns.proposalAccepted')}}</strong>
      <p>{{$t('campaigns.paymentDataregistered')}} <a target="_blank" href="mailto:contacto@brandme.la">contacto@brandme.la</a></p>
    </div>
    <div class="m-2">

      <b-alert :variant="publishedContents() === campaign.campaign_influencer.content.length ? 'success' : 'warning'" show class="p-1 d-flex" v-if="publishedContents() > 0">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
         {{$t('invitation.you_have')}} {{publishedContents(campaign.campaign_influencer.content)}} {{$t('invitation.content_published')}}
        </div>
      </b-alert>

      <b-alert variant="warning" show class="p-1 d-flex" v-if="publishedContents() === 0">
        <div class="mr-1"><feather-icon icon="AlertTriangleIcon" size="20"/></div>
        <div>
          {{$t('invitation.no_content_published')}}
        </div>
      </b-alert>

      <b-alert variant="warning" show class="p-1 d-flex" v-if="!campaign.campaign_influencer.accept_tyc">
        <div class="mr-1"><feather-icon icon="AlertTriangleIcon" size="20"/></div>
        <div>
          {{$t('invitation.dont_forget_tyc')}}
        </div>
      </b-alert>

      <b-alert variant="success" show class="p-1 d-flex" v-if="campaign.campaign_influencer.accept_tyc">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
          {{$t('invitation.accepted_tyc')}}
        </div>
      </b-alert>

      <b-alert variant="warning" show class="p-1 d-flex" v-if="campaign.campaign_influencer.status_influencer !== 'approved'">
        <div class="mr-1"><feather-icon icon="AlertTriangleIcon" size="20"/></div>
        <div>
          {{$t('invitation.accept_campaign')}}
        </div>
      </b-alert>

      <b-alert variant="success" show class="p-1 d-flex" v-if="campaign.campaign_influencer.influencer.has_payment_data">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
          {{$t('invitation.data_bank')}}
        </div>
      </b-alert>

      <b-alert variant="danger" show class="p-1 d-flex" v-if="campaign.campaign_influencer.status_influencer === 'rejected'">
        <div class="mr-1"><feather-icon icon="XSquareIcon" size="20"/></div>
        <div>
          {{$t('invitation.reject_campaign')}}
        </div>
      </b-alert>
      
      <b-button variant="blue-button" class="blue-button w-100" v-if="campaign.campaign_influencer.contract_link && !campaign.campaign_influencer.signed_contract" @click="open_modal_sign = true">{{ $t('invitation.signDocument') }}</b-button>
      <b-alert variant="success" show class="p-1 d-flex" v-if="campaign.campaign_influencer.signed_contract">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
          {{$t('invitation.hasSigned')}}
        </div>
      </b-alert>
    </div>
    
    <b-modal v-model="open_modal_sign" size="xl" centered hide-footer>
      <iframe
        v-if="campaign.campaign_influencer.contract_link && !campaign.campaign_influencer.signed_contract" 
        :src="campaign.campaign_influencer.contract_link"
        class="iframe-sign-pandadoc"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </b-modal>

    <div class="mt-auto border-top-lg pt-1 pl-1 pb-1" v-if="campaign.campaign_influencer.status_influencer === 'pending'">
      <b-link class="decline-campaign" @click="changeStatus('rejected')">{{$t('campaigns.rejectBtn')}}</b-link>
    </div>
  </div>
</template>

<script>
import { 
  BImg,
  BButton, 
  BLink,
  BMedia,
  BAvatar,
  BBadge,
  BAlert,
  BModal,
} from "bootstrap-vue";
import paymentData from "./paymentData.vue";
import invitation_service from "@/services/invitation";
import { getIconWhite, getIconColor } from '@/libs/utils/icons'
import { getImageInfluencer } from '@/libs/utils/others';
import { statusVariant, getStatus } from '@/libs/utils/series_graphs';
import { getFormat, separatebycomma } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'acceptDecline',
  components: {
    BImg,
    BButton,
    BLink,
    paymentData,
    BMedia,
    BAvatar,
    BBadge,
    BAlert,
    BModal,
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      separatebycomma,
      getFormat,
      getUserData,
      getStatus,
      statusVariant,
      getImageInfluencer,
      getIconWhite,
      getIconColor,
      influencer: this.campaign.campaign_influencer.influencer,
      campaign_influencer: this.campaign.campaign_influencer,
      open_modal_sign: false,
    }
  },
  created() {
    const user = getUserData()
    if (user) {
      this.influencer.has_payment_data = user.has_payment_data
    } else {
      this.influencer.has_payment_data = false
    }
  },
  methods: {
    publishedContents() {
      let counter = 0;
      this.campaign.campaign_influencer.content.map((content) => {
        if (content.image_content !== null || content.url_info !== null) counter += 1;
      })
      return counter;
    },
    verifyTYC() {
      if (this.campaign.campaign_influencer.accept_tyc) {
        this.changeStatus('approved');
      } else {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: this.$t('invitation.first_accept_tyc')
            }
          })
        })
      }
    },
    savePaymentData() {
      this.influencer.has_payment_data = true
    },
    changeStatus(status) {
      invitation_service.changeInfluencerStatus(this.campaign.campaign_influencer.uuid, status)
        .then(response => {
          const title = status === 'approved' ? this.$t('campaigns.proposalAccepted') : this.$t('campaigns.proposalRejected')
          const variant = status === 'approved' ? 'success' : 'danger'
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title,
                icon: "BellIcon",
                variant,
              }
            })
          })
          this.campaign.campaign_influencer.status_influencer = response.campaign_influencer.status_influencer
        })
    }
  },
};
</script>

<style>
  .text-no-break{
    justify-content: center !important;
    word-wrap: normal !important
  }
  .end-div {
    margin-top: auto !important;
  }
  .decline-campaign, .decline-campaign:hover {
    color: #B04254;
  }
  .decline-campaign:hover {
    text-decoration: underline;
  }

  .border-top-lg {
    border-top: 1px solid #EFEFEF;
  }
  .container-avatar-table {
    position: relative;
  }
  .media-aside {
    justify-content: center !important;
  }
  .container-avatar-table-icon {
    position: absolute; 
    bottom: 0px; 
    right: 1em;
  }
  .container-avatar-table .b-avatar-img {
    padding: 0.5em !important;
    background-color: #f6f6f6;
    margin: 0 auto !important;
  }
</style>
<style lang="scss" scoped>
.iframe-sign-pandadoc {
  width: 100%;
  height: 90vh;
}
</style>
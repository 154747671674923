<template>
  <div class="m-1">
    <div v-html="campaign.custom_message"></div>
    <b-row class="mt-1">
      <b-col md="12" lg="5">
        <h4 class="mb-0"><strong>{{ getFormatDate(campaign.init_date) }}</strong></h4>
        <p>{{$t('campaigns.startDate')}}</p>
      </b-col>
      <b-col md="12" lg="2">
        <h4 class="mb-0"><strong>-</strong></h4>
      </b-col>
      <b-col md="12" lg="5">
        <h4 class="mb-0"><strong>{{ getFormatDate(campaign.end_date) }}</strong></h4>
        <p>{{$t('campaigns.finishDate')}}</p>
      </b-col>
    </b-row>

    <h3 class="text-blue">{{$t('campaigns.Description')}}</h3>
    <overview-campaign :campaign="campaign" class="mb-1"/>
    <div v-for="(custom_field, index) in custom_fields" :key="index">
      <h3 class="text-blue">{{custom_field.front_name}}</h3>
      <div v-html="custom_field.form_value"></div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol
} from "bootstrap-vue";
import overviewCampaign from "./overviewCampaign.vue";
import utils from "@/libs/utils";

export default {
  name: 'tabOverview',
  components: {
    BRow,
    BCol,
    overviewCampaign,
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      utils,
      custom_fields: []
    }
  },
  created() {
    const only_custom_fields = this.campaign.custom_fields.filter(custom => {
      return custom.type_field === null && custom.show_link
    })
    this.custom_fields = this.orderCustomFields(only_custom_fields)
  },
  methods: {
    getFormatDate(date) {
      return utils.getDatev2(new Date(date))
    },
    orderCustomFields(custom_fields) {
      custom_fields.sort(function(a, b) {
        return a.order - b.order;
      });
      return custom_fields
    },
  }
};
</script>

<style>
.text-blue {
  color: #4b22f4;
}
</style>

<template>
  <b-row class="w-100">
    <b-col cols="12" class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="ready_campaign">
      <b-row v-if="!has_session">
        <b-col cols="12">
          <alert-login></alert-login>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="9" class="col-12 col-sm-12 col-md-12 col-lg-9 mr-0 pr-0">
          <left-side :campaign="campaign" :total_contents="total_contents"/>
        </b-col>
        
        <b-col cols="3" class="col-12 col-md-12 col-lg-3 ml-0 pl-0">
          <right-side :campaign="campaign"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import leftSide from "./newInvitation/leftSide.vue";
import rightSide from "./newInvitation/rightSide.vue";
import invitationService from "@/services/invitation";
import alertLogin from "./newInvitation/alertLogin.vue";

export default {
  data() {
    return {
      campaign: {},
      ready_campaign: false,
      total_contents: 0,
      has_session: false
    };
  },
  components: {
    BRow,
    BCol,
    leftSide,
    rightSide,
    alertLogin
  },
  created() {
    this.getCampaign()
    this.has_session = localStorage.getItem('accessToken') !== null

    if (!this.has_session) {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' })
    } else {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'static' })
      this.validateSession()
    }

    const self = this
    setTimeout(() => {
      self.checkInvitation();
    }, 1000)
  },
  methods: {
    validateSession() {
      invitationService.validateSession(this.$route.params.slug, this.$route.params.network, this.$route.params.username)
        .then(response => {
          if (!response.valid) {
            this.$router.push({name: 'dashboard'})
          }
        })
    },
    plusContent() {
      this.total_contents += 1;
    },
    getCampaign() {
      invitationService.getInvitation(this.$route.params.slug, this.$route.params.network, this.$route.params.username).then((response) => {
        this.campaign = response;
        this.total_contents = this.campaign.campaign_influencer.content.length
        this.ready_campaign = true;
      });
    },
    checkInvitation() {
      window.HubSpotConversations.widget.remove();
    },
  },
};
</script>

<style>
.header-invitation {
  background: linear-gradient(120deg ,#6947bc, #993e90);
  display: flex;
  justify-content: center;
  padding: 1.1rem 2rem;
}
.card-header-invitation {
  margin-bottom: -4em !important;
}
.card-header-invitation img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.container-avatar {
  width: auto;
  position: relative;
  bottom: 4.5em;
}
.avatar-header {
  min-width: 120px;
  padding: 0.7em;
}
.avatar-influencer {
  padding: 0.7em;
}
.socialnet{
  font-size: 2em;
  position: absolute;
  right: 30%;
  top: 33%;
  background: white;
  z-index: 9;
  padding: 2%;
  border-radius: 50%;
 }
@media(min-width:2000px) {
  .socialnet {
    right:35%;
  }
}
@media(min-width:2900px) {
  .socialnet {
    right:40%;
  }
}
.icon-verified-invitation {
  width: 20px !important;
  height: 20px !important;
  margin-left: 0.2em;
}
.fixed-height {
    height: calc(100vh - 150px);
    overflow: scroll;
  }
.container-invitation {
  height: calc(100vh - 150px);
}
@media(min-width:1200px) {
  .container-invitation {
    padding: 2rem 6rem 0 6rem !important;
  }
}
.no-radius {
  border-radius: 0;
  box-shadow: none;
}
.border-right-lg {
  border-right: 1px solid #EFEFEF;
}
</style>
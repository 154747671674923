<template>
  <b-alert class="bg-alert-success" show>
    <p class="p-2 jumbo-text">
      {{$t('invitation.openCampaign', {total_contents: total_contents})}}
    </p>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";

export default {
  name: 'BannerStatus',
  components: {
    BAlert
  },
  props: {
    total_contents: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style>
  .bg-alert-success {
    background-image: linear-gradient(to left,#237ca4,#57daac) !important;
    color: #FFF !important;
  }
  .jumbo-text {
    font-size: 28px;
    font-weight: 500;
  }
</style>

<template>
  <div>
    <div ref="overview" class="collapsable-overview div-description" v-html="campaign.description"></div>
    <div class="mt-1" v-if="more_description">
      <b-link @click="toggleOverview()">{{show_more ? $t('instagram.seeMore') : $t('instagram.seeLess')}}</b-link>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  name: 'OverviewCampaign',
  components: {
    BLink
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      show_more: true,
      more_description: false
    }
  },
  created() {
    setTimeout(() => {
      this.more_description = this.$refs.overview.clientWidth < this.$refs.overview.scrollWidth || 50 < this.$refs.overview.scrollHeight;
    }, 50)
  },
  methods: {
    toggleOverview() {
      this.show_more = !this.show_more
      this.$refs.overview.classList.toggle("collapsable-overview")
    }
  }
};
</script>

<style>
  .collapsable-overview {
    height: 50px;
    overflow: hidden;
  }
  .div-description > ul {
    margin-left: 2rem;
  }
</style>

<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span>{{ $t('login.text') }} </span>
        <span><strong class="cursor-pointer" @click="modalLogin()">{{$t('login.wantRegister')}}</strong></span>
      </div>
    </b-alert>
  
    <modal-brandme :show_modal="show_modal" :key="change_modal" bodyClass="p-0" size="lg">
      <template #title>{{ $t('login.create') }}</template>
      <template #body>
        <b-tabs fill nav-class="mb-0">
          <b-tab title="Registro">
            <register-invitation></register-invitation>
          </b-tab>
          <b-tab title="Inicia sesión">
            <login-invitation></login-invitation>
          </b-tab>
        </b-tabs>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import { BAlert, BTabs, BTab } from "bootstrap-vue";
import ModalBrandme from "@/views/components/modal/ModalBrandme.vue";
import registerInvitation from "@/views/pages/invitation/newInvitation/forms/registerInvitation.vue"
import loginInvitation from '@/views/pages/invitation/newInvitation/forms/loginInvitation.vue'

export default {
  name: 'alertLogin',
  components: {
    BAlert,
    BTabs,
    BTab,
    ModalBrandme,
    registerInvitation,
    loginInvitation
  },
  props: {
  },
  data() {
    return {
      show_modal: false,
      change_modal: false
    }
  },
  created() {
  },
  methods: {
    modalLogin() {
      this.show_modal = true
      this.change_modal = !this.change_modal
    }
  },
  watch: {
  }
};
</script>

<style>
</style>
